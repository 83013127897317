import { SvgSprite } from "../sprites/svg-sprite";
import { mainNavigation } from '../global/scripts/navigation';
import { Accordion } from '../global/scripts/accordion';
import { Tabs } from '../global/scripts/tabs';
import { bioBlock } from "../global/scripts/bioBlock";
import { MediaCarousel } from "../global/scripts/carousel";
import { heroBlock } from "../global/scripts/heroBlock";
import { downloadBlock } from "../global/scripts/downloadBlock";
import { upcomingEvents } from "../global/scripts/upcomingEvents";
import { Modal } from "../global/scripts/floatBlock";
import { audioplayer } from "./scripts/audioPlayer";
import { surveyForm } from "./scripts/surveyBlock";
import { createTour } from "./scripts/tour";
declare global {
    interface Window {
        SurveyForm: (formId) => void;
    }
}

window.SurveyForm = function (formId) { surveyForm(formId); };

const initializeGenericComponents = () => {
    // Request SVG sprite
    var hash = (Math.floor(Math.random() * 1000)).toString();
    const sprites = new SvgSprite('/Static/images/svg-sprite.svg?v=' + hash)
    // End Request SVG sprite

    navigationInit();
    const bioBlockContainers = document.querySelectorAll('[data-bio-block]');
    if (bioBlockContainers.length > 0) {
        bioBlock(bioBlockContainers);
    }

    const heroBlocks = document.querySelectorAll('[data-vimeo-hero]');
    if (heroBlocks.length > 0) {
        heroBlock(heroBlocks);
    }

    const donwloadContainer = document.querySelectorAll('[data-download-container]');
    if (donwloadContainer.length > 0) {
        downloadBlock(donwloadContainer);
    }
    const upcomingEventsContainers = document.querySelectorAll('[data-upcoming-events]');
    if (upcomingEventsContainers.length > 0) {
        upcomingEvents(upcomingEventsContainers);
    }

    const accordions = [].slice.call(document.querySelectorAll('[data-accordion-list]')) as HTMLElement[];
    accordions.forEach(accordionEl => {
        const oneOpenAtATime = accordionEl.getAttribute('data-one-open-at-a-time') == "true";
        new Accordion({ container: accordionEl, oneOpenAtATime: oneOpenAtATime });
    });

    const tabEls = Array.prototype.slice.call(document.querySelectorAll('[data-tabs-container]')) as HTMLElement[];
    tabEls.forEach(tabEl => {
        new Tabs({ container: tabEl });
    });

    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]')) as HTMLElement[];
    modals.forEach(modal => {
        new Modal(modal);
    });

    const mediaCarousels = document.querySelectorAll('[data-media-carousel]');
    const carouselClass = 'media-carousel';

    mediaCarousels.forEach((carousel, index) => {
        carousel.classList.add(`${carouselClass}-${index}`);
        let autoplay: any = false;
        if (carousel.getAttribute('data-autoplay').toLowerCase() == "true") {
            autoplay = { delay: 5000 };
        }
        new MediaCarousel({
            containerClass: `${carouselClass}-${index}`,
            slidesPerView: 1,
            loop: false,
            autoplay: autoplay,
            centeredSlides:true
        })
    });

    const contentCarousels = document.querySelectorAll('[data-content-highlight-carousel]');
    const contentCarouselClass = 'content-carousel';
    contentCarousels.forEach((contentCarousel, index) => {
        contentCarousel.classList.add(`${contentCarouselClass}-${index}`);
        new MediaCarousel({
            containerClass: `${contentCarouselClass}-${index}`,
            slidesPerView: 1,
            loop: false,
            
        })
    });

    const adCarousels = document.querySelectorAll('[data-ad-carousel]');
    const adCarouselClass = 'ad-carousel';
    adCarousels.forEach((adCarousel, index) => {
        let autoplay: any = false;
        if (adCarousel.getAttribute('data-autoplay').toLowerCase() == "true") {
            if (adCarousel.getAttribute('data-autoplay-delay')) {
                autoplay = { delay: parseInt(adCarousel.getAttribute('data-autoplay-delay')) };
            }
            else {
                autoplay = { delay: 5000 };
            }
        }
        adCarousel.classList.add(`${adCarouselClass}-${index}`);
        new MediaCarousel({
            containerClass: `${adCarouselClass}-${index}`,
            slidesPerView: 1,
            loop: true,
            autoplay: autoplay,
            navigation: {
                enabled: false,
            },
            autoHeight:true,

        })
    });

    const audioPlayerContainers = document.querySelectorAll('[data-audio-player]');
    if (audioPlayerContainers.length > 0) {
        audioplayer(audioPlayerContainers);
    }
    const tourList = (window as any).tour?.simpleTourItems;
    if (tourList != null && tourList.length > 0) { 
        tourList.forEach((tourItem) => {
            let tourElement = document.querySelector(tourItem.elementSelector);
            if (tourElement) {
                createTour(tourElement, tourItem);
            }
        });
    }
}

const navigationInit = () => {
    mainNavigation.init();
}


initializeGenericComponents();